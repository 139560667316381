/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {faHome, faSignInAlt, faSignOutAlt, faUser, faUserPlus, faCog, faX} from '@fortawesome/free-solid-svg-icons'


library.add(
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faCog,
    faX
);

export { FontAwesomeIcon };
