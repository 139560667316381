<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-light static-top bg-light">
      <div class="container">
        <RouterLink to="/" class="navbar-brand me-2" exact-active-class=''><img src="/header_logo.png" alt="logo" height="90"/></RouterLink>
        <button class="navbar-toggler"
                data-bs-auto-close="true"
                type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarToggler"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <RouterLink to="/services" class="nav-link">Services</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink to="/gallery" class="nav-link">Gallery</RouterLink>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://www.google.com/search?sca_esv=558882007&sxsrf=AB5stBj6dYeVETGDndLyQWqfk1Z1vOrQtA:1692659075128&q=Leveled+Up+Chimney+Services&ludocid=12074592210260636491&lsig=AB86z5WK3SRcBly6xbJfdcA34kRv&kgs=14a3f4c6902f8723&shndl=-1&source=sh/x/kp/local/m1/can/3&asid=canlbse#lrd=0xdf9879d25d95e81:0xa79191a42c48b34b,1,,,,">Customer Feedback</a>
            </li>
            <li class="nav-item">
              <RouterLink to="/contact" class="nav-link">Contact</RouterLink>
            </li>
            <li class="nav-item">
              <RouterLink to="/schedule" class="nav-link">Schedule Service</RouterLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">

</script>
