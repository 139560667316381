<template>
  <div class="container">
      <h1 class="h1-responsive font-weight-bold text-center my-4">Contact us</h1>
      <!--Section description-->
      <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>

      <div class="row container text-center">

        <!--Grid column-->
        <div class="text-center">
          <ul class="list-unstyled mb-0">
            <li><i class="fas fa-map-marker-alt fa-2x"></i>
              <p>Marysville, WA</p>
            </li>

            <li><i class="fas fa-phone mt-4 fa-2x"></i>
              <p>(360) 722-1909</p>
            </li>

            <li><i class="fas fa-envelope mt-4 fa-2x"></i>
              <p>contact@leveledupchimneyservices.com</p>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script setup lang="ts">


</script>

