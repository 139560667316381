import { createApp } from "vue";
import App from "./components/layout/App.vue";
import router from "./routes/router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { createPinia } from "pinia";
import {FontAwesomeIcon} from "@/plugins/font-awesome";
import piniaPersist from 'pinia-plugin-persist'

const pinia = createPinia();
pinia.use(piniaPersist)

createApp(App)
    .use(pinia)
    .use(router)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
