<template>
  <div class="container h-100">
    <h1>Schedule</h1>
    <iframe height="1600px" class="iframe" src="https://book.housecallpro.com/book/Leveled-Up-Chimney-Services-LLC/9b96879a1b714ee1ba98d7c5d5711f92"/>
  </div>
</template>

<style>
  .iframe {
    width: 100%;
    min-height: 100%;
  }
</style>

<script setup lang="ts">


</script>

