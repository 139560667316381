<template>
  <div class="">
    <div id="myCarousel" class="margin-top-override carousel slide" data-bs-ride="carousel" style="background: #646464">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner container">
        <div class="carousel-item active">
          <!--                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>-->
          <div class="text-center">
            <img src="/thumb.png"/>
          </div>
          <div class="container">
            <div class="carousel-caption text-start">
              <h1>We're CSIA Certified</h1>
              <p>Being certified with the Chimney Safety Institute of America really <b>levels up</b> the services we provide.</p>
              <p><a class="btn btn-lg btn-primary" href="https://www.csia.org/industrystandard.html" target="_blank">Learn more</a></p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
          <img src="/marysville.jpg"/>
          <div class="container">
            <div class="carousel-caption">
              <h1>Locally owned and operated</h1>
              <p>We love helping our neighbors improve their property value and keep their homes safe for their loved ones.</p>
              <p><a class="btn btn-lg btn-primary" target="_blank" href="https://www.google.com/maps/place/Marysville,+WA/@48.0531721,-122.181292,3231m/data=!3m1!1e3!4m6!3m5!1s0x548553b96557a771:0x72449f271b24ff0!8m2!3d48.0517637!4d-122.1770818!16zL20vMDEwdHJx!5m2!1e3!1e4?entry=ttu">Service area</a></p>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
          <img src="/img.png"/>
          <div class="container">
            <div class="carousel-caption text-end">
              <h1>Book an estimate</h1>
              <p>Our technicians would love to come review your project. When you book your service with us, your estimate is free.</p>
              <RouterLink exact-active-class='' to="/schedule"><button class="btn btn-lg btn-primary">Book Now</button></RouterLink>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <div class="container">

      <!-- Three columns of text below the carousel -->
      <div class="row">
        <div class="col-lg-4 mb-5">
          <div class="text-center">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="/darrell.png" role="img" aria-label="Placeholder: 140x140" />
          </div>

          <h2 class="text-center">Meet our team</h2>
          <p class="text-center">Darrell Anderson has years of experience and opened his own company, Leveled Up, so he could set a higher standard of safety, craftsmanship, and care in his local Chimney industry.</p>
          <router-link to="/team">
            <div class="text-center">
              <button class="btn btn-secondary">
                View Details
              </button>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4 mb-5">
          <div class="text-center">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="/services.png" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/>
          </div>
          <h2 class="text-center">The services we provide</h2>
          <p class="text-center">Whether it's cleaning, inspections, repairs, or full rebuilds of your chimney, we're here for your chimney and masonry needs.</p>
          <router-link to="/services">
            <div class="text-center">
              <button class="btn btn-secondary">
                View Details
              </button>
            </div>
          </router-link>
          <!--          <p class="text-center"><a class="btn btn-secondary" href="#">View details &raquo;</a></p>-->
        </div>
        <div class="col-lg-4 mb-5">
          <div class="text-center">
            <img class="bd-placeholder-img rounded-circle" width="140" height="140" src="/5stars.png" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"/>
          </div>
          <h2 class="text-center">Customer feedback</h2>
          <p class="text-center">Our customers experience, outcomes, and feedback are very important to us. See what they have to say about it.</p>
          <p class="text-center"><a class="btn btn-secondary"  target="_blank" href="https://www.google.com/search?sca_esv=558882007&sxsrf=AB5stBj6dYeVETGDndLyQWqfk1Z1vOrQtA:1692659075128&q=Leveled+Up+Chimney+Services&ludocid=12074592210260636491&lsig=AB86z5WK3SRcBly6xbJfdcA34kRv&kgs=14a3f4c6902f8723&shndl=-1&source=sh/x/kp/local/m1/can/3&asid=canlbse#lrd=0xdf9879d25d95e81:0xa79191a42c48b34b,1,,,,">View details &raquo;</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.margin-top-override {
  //margin-top: -30px;
}
</style>

<script setup lang="ts">


</script>

