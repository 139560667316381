<template>
  <div class="container h-100">
    <h1>Gallery</h1>
  </div>
</template>

<style>
  .iframe {
    width: 100%;
    min-height: 100%;
  }
</style>

<script setup lang="ts">


</script>

