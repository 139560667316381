import {createWebHistory, createRouter, RouteRecordRaw} from "vue-router";
import HomeView from "@/components/view/HomeView.vue";
import ServicesView from "@/components/view/ServicesView.vue";
import GalleryView from "@/components/view/GalleryView.vue";
import CustomerFeedbackView from "@/components/view/CustomerFeedbackView.vue";
import ContactView from "@/components/view/ContactView.vue";
import ScheduleService from "@/components/view/ScheduleView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: HomeView,
    children: []
  },
  {
    path: "/services",
    component: ServicesView,
    children: []
  },
  {
    path: "/gallery",
    component: GalleryView,
    children: []
  },
  {
    path: "/customer-feedback",
    component: CustomerFeedbackView,
    children: []
  },
  {
    path: "/contact",
    component: ContactView,
    children: []
  },
  {
    path: "/schedule",
    component: ScheduleService,
    children: []
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "nav-active"
});

export default router;
